import currentGlobal from "@/common/global"
import SecureLS from "secure-ls"

export class MemoryStorage implements Storage {
  storage: {
    [name: string]: any
  } = {}

  setItem(key: string, value: any) {
    this.storage[key] = value
  }

  getItem(key: string) {
    return this.storage[key]
  }

  removeItem(key: string) {
    delete this.storage[key]
  }

  clear() {
    this.storage = {}
  }

  key(index: number) {
    return Object.keys(this.storage)[index]
  }

  get length() {
    return Object.keys(this.storage).length
  }
}

// sessionStorage
if (typeof sessionStorage === "undefined" || sessionStorage === null) {
  global.sessionStorage = new MemoryStorage()
}

// localStorage
if (typeof localStorage === "undefined" || localStorage === null) {
  global.localStorage = new MemoryStorage()
}

const ls = new SecureLS()

interface EncryptedStorage extends Storage {
  getEncryptedItem(key: string): string | null
  setEncryptedItem(key: string, value: string): void
}

const storageFactory = (storage: Storage): EncryptedStorage => {
  let inMemoryStorage: { [key: string]: string } = {}
  const length = 0

  function isSupported() {
    try {
      const testKey = "_placeholder_"
      storage.setItem(testKey, testKey)
      storage.removeItem(testKey)
      return true
    } catch (e) {
      return false
    }
  }

  function clear(): void {
    if (isSupported()) {
      storage.clear()
    } else {
      inMemoryStorage = {}
    }
  }

  function getItem(name: string): string | null {
    if (isSupported()) {
      return storage.getItem(name)
    }
    if (Object.prototype.hasOwnProperty.call(inMemoryStorage, name)) {
      return inMemoryStorage[name]
    }
    return null
  }

  function key(index: number): string | null {
    if (isSupported()) {
      return storage.key(index)
    } else {
      return Object.keys(inMemoryStorage)[index] || null
    }
  }

  function removeItem(name: string): void {
    if (isSupported()) {
      const encryptedName = `${name}_encrypted`
      storage.removeItem(name)
      storage.removeItem(encryptedName)
    } else {
      delete inMemoryStorage[name]
    }
  }

  function setItem(name: string, value: string): void {
    if (isSupported()) {
      storage.setItem(name, value)
    } else {
      inMemoryStorage[name] = String(value)
    }
  }

  function getEncryptedItem(name: string): string | null {
    if (isSupported()) {
      const encryptedName = `${name}_encrypted`
      return ls.get(encryptedName) || storage.getItem(name)
    }
    if (Object.prototype.hasOwnProperty.call(inMemoryStorage, name)) {
      return inMemoryStorage[name]
    }
    return null
  }

  function setEncryptedItem(name: string, value: string): void {
    if (isSupported()) {
      const encryptedName = `${name}_encrypted`
      ls.set(encryptedName, value)
      storage.removeItem(name)
    } else {
      inMemoryStorage[name] = String(value)
    }
  }

  return {
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    length,
    getEncryptedItem,
    setEncryptedItem,
  }
}

export const storageLocal = storageFactory(currentGlobal.localStorage)
export default storageLocal
